import { IRole } from "../models/role/response";
import { ISkillQuery } from "../models/skills/query";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class PermissionsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPermissions = (query: ISkillQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<any>>({
      path: `/permissions`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

}

export default PermissionsService;
