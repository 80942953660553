import { IStudentClassBehaviorQuery } from "../models/studentClassBehavior/query";
import {
  ICreateStudentClassBehavior,
  IUpdateStudentClassBehavior,
} from "../models/studentClassBehavior/request";
import {
  IStudentClassBehavior,
  IStudentClassBehaviorDetails,
} from "../models/studentClassBehavior/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentClassBehaviorService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsBehaviorClass = (
    query: IStudentClassBehaviorQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudentClassBehavior>>({
      path: `${path == "supervisor-panel" ? "supervisor-panel/behaviors" : `${path ?? ""}/student-class-behavior`}`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getTeachearStudentsBehaviorClass = (
    query: IStudentClassBehaviorQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudentClassBehavior>>({
      path: `/teacher-panel/student-class-behavior`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentBehaviorClass = (
    id: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<any>({
      path: `${path ?? ""}/student-class-behavior/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentBehaviorClass = (
    data: ICreateStudentClassBehavior,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClassBehavior>({
      path: `${path ?? ""}/student-class-behavior`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentBehaviorClass = (
    id: number,
    data: IUpdateStudentClassBehavior,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClassBehavior>({
      path: `${path ?? ""}/student-class-behavior/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  updateApprovalBehaviorClass = (
    id: number,
    data: any,
    params?: RequestParams
  ) =>
    this.http.request<any>({
      path: `/supervisor-panel/student-class-behaviors/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentBehaviorClass = (
    id: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClassBehavior>({
      path: `${path ?? ""}/student-class-behavior/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentClassBehaviorService;
