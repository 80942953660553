import { IStudentMessageQuery } from "../models/studentsMessage/query";
import {
  ICreateStudentMessage,
  IUpdateStudentMessage,
} from "../models/studentsMessage/request";
import {
  IStudentMessage,
  IStudentMessageDetails,
} from "../models/studentsMessage/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentsMessageService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsMessages = (
    query: IStudentMessageQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudentMessage>>({
      path: `${path ?? ""}/messages`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getTeachearStudentsMessages = (
    query: IStudentMessageQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudentMessage>>({
      path: `/teacher-panel/messages`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentMessages = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IStudentMessageDetails>({
      path: `${path ?? ""}/messages/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentMessages = (
    data: ICreateStudentMessage,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IStudentMessage>({
      path: `${path ?? ""}/messages`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  createActualMessages = (
    data: ICreateStudentMessage,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IStudentMessage>({
      path: `${path ?? ""}/message-class`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentMessages = (
    id: number,
    data: IUpdateStudentMessage,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IStudentMessage>({
      path: `${path ?? ""}/messages/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  updateApprovalStudentMessages = (
    id: number,
    data: any,
    params?: RequestParams
  ) =>
    this.http.request<any>({
      path: `/supervisor-panel/messages/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentMessages = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IStudentMessage>({
      path: `${path ?? ""}/messages/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentsMessageService;
