import { IClassesQuery } from "../models/classes/query";
import { ICreateClasses, IUpdateClasses } from "../models/classes/request";
import { IClasses, IClassesDetails } from "../models/classes/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ClassesService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClasses = (query: IClassesQuery, path?: string, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IClasses>>({
      path: `${path ?? ""}/classes`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getClasse = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IClassesDetails>({
      path: `${path ?? ""}/classes/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createClasse = (data: ICreateClasses, path?: string, params?: RequestParams) =>
    this.http.request<IClasses>({
      path: `${path ?? ""}/classes`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateClasse = (id: number, data: IUpdateClasses, path?: string, params?: RequestParams) =>
    this.http.request<IClasses>({
      path: `${path ?? ""}/classes/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteClasse = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IClasses>({
      path: `${path ?? ""}/classes/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ClassesService;
