import { IAcademicLevelQuery } from "../models/academicLevels/query";
import {
  ICreateAcademicLevel,
  IUpdateAcademicLevel,
} from "../models/academicLevels/request";
import {
  IAcademicLevel,
  IAcademicLevelDetails,
} from "../models/academicLevels/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class AcademicLevelService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllAcademicLevels = (
    query: IAcademicLevelQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IAcademicLevel>>({
      path: `${path ?? ""}/academic-level`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getAcademicLevel = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IAcademicLevelDetails>({
      path: `${path ?? ""}/academic-level/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createAcademicLevel = (
    data: ICreateAcademicLevel,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IAcademicLevel>({
      path: `${path ?? ""}/academic-level`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateAcademicLevel = (
    id: number,
    data: IUpdateAcademicLevel,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IAcademicLevel>({
      path: `${path ?? ""}/academic-level/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteAcademicLevel = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IAcademicLevel>({
      path: `${path ?? ""}/academic-level/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default AcademicLevelService;
