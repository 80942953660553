import { IAcademicLevelQuery } from "../models/academicLevels/query";
import {
  ICreateAcademicLevel,
  IUpdateAcademicLevel,
} from "../models/academicLevels/request";
import {
  IAcademicLevel,
  IAcademicLevelDetails,
} from "../models/academicLevels/response";
import { IPublicNotificationQuery } from "../models/publicNotification/query";
import {
  ICreatePublicNotification,
  IUpdatePublicNotification,
} from "../models/publicNotification/request";
import {
  IPublicNotification,
  IPublicNotificationDetails,
} from "../models/publicNotification/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class PublicNotificationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPublicNotifications = (
    query: IPublicNotificationQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IPublicNotification>>({
      path: `${path ?? ""}/public-notification`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getPublicNotification = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IPublicNotificationDetails>({
      path: `${path ?? ""}/public-notification/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createPublicNotification = (
    data: ICreatePublicNotification,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IPublicNotificationDetails>({
      path: `${path ?? ""}/public-notification`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  sendPublicNotification = (
    id: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IPublicNotificationDetails>({
      path: `${path ?? ""}/public-notification/${id}/send`,
      method: "POST",
      secure: true,
      ...params,
    });

  updatePublicNotification = (
    id: number,
    data: IUpdatePublicNotification,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IPublicNotificationDetails>({
      path: `${path ?? ""}/public-notification/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deletePublicNotification = (
    id: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IPublicNotificationDetails>({
      path: `${path ?? ""}/public-notification/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default PublicNotificationService;
