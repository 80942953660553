import { IReasonCancelQuery } from "../models/reasonsCancel/query";
import {
  ICreateReasonCancel,
  IUpdateReasonCancel,
} from "../models/reasonsCancel/request";
import {
  IReasonCancel,
  IReasonCancelDetails,
} from "../models/reasonsCancel/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ReasonCancelService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllReasonCancel = (
    query: IReasonCancelQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IReasonCancel>>({
      path: `${path ?? ""}/reason-cancellation`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getReasonCancel = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IReasonCancelDetails>({
      path: `${path ?? ""}/reason-cancellation/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createReasonCancel = (
    data: ICreateReasonCancel,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IReasonCancel>({
      path: `${path ?? ""}/reason-cancellation`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateReasonCancel = (
    id: number,
    data: IUpdateReasonCancel,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IReasonCancel>({
      path: `${path ?? ""}/reason-cancellation/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteReasonCancel = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IReasonCancel>({
      path: `${path ?? ""}/reason-cancellation/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ReasonCancelService;
