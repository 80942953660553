import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import EndPoints from "../services/end-points";
import { httpclient } from "../services/http-client";
import { errorNotification } from "../utils/helpers/notification";
import { EMP, TYPE } from "../utils/constants";

enum LoadingEnum {
  IDLE = "idle",
  PENDING = "pending",
  SUCCESS = "succeeded",
  FAILED = "failed",
}

const initialState = {
  count: 0,
  loading: "idle",
  error: null,
} as {
  count: null | number;
  loading: LoadingEnum;
  error: null | SerializedError;
};


export const UnreadNotifications = createAsyncThunk(
  "notifications/unread-notifications-count",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await EndPoints.notifications
        .getCountNotifications(
          localStorage.getItem(TYPE) == EMP ? "" : "school-panel"
        );
      return response.data;
    } catch (error: any) {
      errorNotification(error.response.data?.errors[0]);
      return rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: "notify",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(UnreadNotifications.pending, (state) => {
        state.count = null;
        state.loading = LoadingEnum.PENDING;
        state.error = null;
      })
      .addCase(UnreadNotifications.fulfilled, (state, action) => {
        state.count = action.payload ?? 0;
        state.loading = LoadingEnum.SUCCESS;
        state.error = null;
      })
      .addCase(UnreadNotifications.rejected, (state, action) => {
        state.count = null;
        state.loading = LoadingEnum.FAILED;
        state.error = action.error;
      });
  },
});

export default slice.reducer;
