import { IClassroomQuery } from "../models/classroom/query";
import {
  ICreateClassroom,
  IUpdateClassroom,
} from "../models/classroom/request";
import { IClassroom, IClassroomDetails } from "../models/classroom/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ClassroomService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClassroom = (query: IClassroomQuery, path?: string, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IClassroom>>({
      path: `${path ?? ""}/classroom`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getAllClassroomSuperVisor = (
    schoolId?: number,
    query?: IClassroomQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClassroom>>({
      path: `/supervisor-panel/classrooms?school=${schoolId}`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getAllClassroomTeachear = (
    schoolId?: number,
    query?: IClassroomQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClassroom>>({
      path: `/teacher-panel/classrooms?school=${schoolId}`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getClassroom = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IClassroomDetails>({
      path: `${path ?? ""}/classroom/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createClassroom = (data: ICreateClassroom, path?: string, params?: RequestParams) =>
    this.http.request<IClassroom>({
      path: `${path ?? ""}/classroom`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateClassroom = (
    id: number,
    data: any, path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroom>({
      path: `${path ?? ""}/classroom/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteClassroom = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IClassroom>({
      path: `${path ?? ""}/classroom/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ClassroomService;
