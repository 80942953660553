import { IGradesQuery } from "../models/grades/query";
import { ICreateGrade, IUpdateGrade } from "../models/grades/request";
import { IGrade, IGradeDetails } from "../models/grades/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class GradesService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllGrades= (query: IGradesQuery, path?: string, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IGrade>>({
      path: `${path ?? ""}/grades`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getGrade = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IGradeDetails>({
      path: `${path ?? ""}/grades/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createGrade = (data: ICreateGrade, path?: string, params?: RequestParams) =>
    this.http.request<IGrade>({
      path: `${path ?? ""}/grades`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateGrade = (
    id: number,
    data: IUpdateGrade, path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IGrade>({
      path: `${path ?? ""}/grades/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteGrade = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IGrade>({
      path: `${path ?? ""}/grades/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default GradesService;
