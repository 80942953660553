import { IClassesDetails } from "../models/classes/response";
import { IMarkDetails } from "../models/degree/response";
import { IHomeworkDetails } from "../models/homeworks/response";
import { ISkillQuery } from "../models/skills/query";
import { IStudentClass } from "../models/studentClass/response";
import { IStudentClassBehaviorDetails } from "../models/studentClassBehavior/response";
import { IStudentClassSkillDetails } from "../models/studentClassSkill/response";
import { IStudentMessageDetails } from "../models/studentsMessage/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ReportsService<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>;

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http;
    }

    getAttendances = (query: ISkillQuery, params?: RequestParams) =>
        this.http.request<IBaseListingResponse<IStudentClass>>({
            path: `/manager-panel/homepage/attendances`,
            method: "GET",
            query,
            secure: true,
            ...params,
        });

    getStudentBehaviors = (query: ISkillQuery,  params?: RequestParams) =>
        this.http.request<IBaseListingResponse<IStudentClassBehaviorDetails>>({
            path:  `/manager-panel/homepage/student-behaviors`,
            method: "GET",
            query,
            secure: true,
            ...params,
        });

    getStudentSkills = (query: ISkillQuery,  params?: RequestParams) =>
        this.http.request<IBaseListingResponse<IStudentClassSkillDetails>>({
            path:  `/manager-panel/homepage/student-skills`,
            method: "GET",
            query,
            secure: true,
            ...params,
        });

    getAnnouncements = (query: ISkillQuery, params?: RequestParams) =>
        this.http.request<IBaseListingResponse<IStudentMessageDetails>>({
            path:  `/manager-panel/homepage/announcements`,
            method: "GET",
            query,
            secure: true,
            ...params,
        });

    getMarks = (query: ISkillQuery, params?: RequestParams) =>
        this.http.request<IBaseListingResponse<IMarkDetails>>({
            path:  `/manager-panel/homepage/marks`,
            method: "GET",
            query,
            secure: true,
            ...params,
        });

    getHomeworks = (query: ISkillQuery,  params?: RequestParams) =>
        this.http.request<IBaseListingResponse<IHomeworkDetails>>({
            path:  `/manager-panel/homepage/homeworks`,
            method: "GET",
            query,
            secure: true,
            ...params,
        });

    getSessions = (query: ISkillQuery,  params?: RequestParams) =>
        this.http.request<IBaseListingResponse<IClassesDetails>>({
            path: `/manager-panel/homepage/sessions`,
            method: "GET",
            query,
            secure: true,
            ...params,
        });


}

export default ReportsService;
