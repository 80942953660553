import { IClassroomBookQuery } from "../models/classroomBook/query";
import {
  ICreateClassroomBook,
  IUpdateClassroomBook,
} from "../models/classroomBook/request";
import {
  IClassroomBook,
  IClassroomBookDetails,
} from "../models/classroomBook/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ClassroomBooksService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClassroomBooks = (
    query: IClassroomBookQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClassroomBook>>({
      path: `${path ?? ""}/classroom-book`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getClassroomBook = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IClassroomBookDetails>({
      path: `${path ?? ""}/classroom-book/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createClassroomBook = (
    data: ICreateClassroomBook,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomBook>({
      path: `${path ?? ""}/classroom-book`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateClassroomBook = (
    id: number,
    data: IUpdateClassroomBook,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomBook>({
      path: `${path ?? ""}/classroom-book/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteClassroomBook = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IClassroomBook>({
      path: `${path ?? ""}/classroom-book/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ClassroomBooksService;
