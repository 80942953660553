import { IPeriodAcademicQuery } from "../models/periodAcademic/query";
import {
  ICreatePeriodAcademic,
  IUpdatePeriodAcademic,
} from "../models/periodAcademic/request";
import {
  IPeriodAcademic,
  IPeriodAcademicDetails,
} from "../models/periodAcademic/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class PeriodAcademicService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPeriodAcademics = (
    query: IPeriodAcademicQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IPeriodAcademic>>({
      path: `${path ?? ""}/periodAcademic`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getPeriodAcademic = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IPeriodAcademicDetails>({
      path: `${path ?? ""}/periodAcademic/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createPeriodAcademic = (
    data: ICreatePeriodAcademic,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IPeriodAcademic>({
      path: `${path ?? ""}/periodAcademic`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updatePeriodAcademic = (
    id: number,
    data: any,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IPeriodAcademic>({
      path: `${path ?? ""}/periodAcademic/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deletePeriodAcademic = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IPeriodAcademic>({
      path: `${path ?? ""}/periodAcademic/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default PeriodAcademicService;
