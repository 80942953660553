import { RootState } from "./store";

export const getIsLoading = (state: RootState) => state.app?.isLoading;
export const getIsAuthenticated = (state: RootState) =>
  state.user?.isAuthenticated;
export const getAlert = (state: RootState) => state.app?.alert;
export const getLanguage = (state: RootState) => state.app?.language;
export const getTheme = (state: RootState) => state.app?.theme;
export const getDirection = (state: RootState) => {
  return state.app?.language === "ar" ? "rtl" : "ltr";
};
export const getScreenSize = (state: RootState) => state.app?.screenSize;

export const getUser = (state: RootState) => state.user?.me;
export const getAuth = (state: RootState) => state.auth;
export const getnotifyCount = (state: RootState) => state.notify;