import { IClassroomEvaluationComponentQuery } from "../models/classroomEvaluationComponent/query";
import {
  ICreateClassroomEvaluationComponent,
  IUpdateClassroomEvaluationComponent,
} from "../models/classroomEvaluationComponent/request";
import {
  IClassroomEvaluationComponent,
  IClassroomEvaluationComponentDetails,
} from "../models/classroomEvaluationComponent/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ClassroomEvaluationComponentsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClassroomEvaluationComponents = (
    query: IClassroomEvaluationComponentQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClassroomEvaluationComponent>>({
      path: `${path ?? ""}/classroom-evaluation-components`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getClassroomEvaluationComponent = (
    id: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomEvaluationComponentDetails>({
      path: `${path ?? ""}/classroom-evaluation-components/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createClassroomEvaluationComponent = (
    data: ICreateClassroomEvaluationComponent,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomEvaluationComponent>({
      path: `${path ?? ""}/classroom-evaluation-components`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateClassroomEvaluationComponent = (
    id: number,
    data: IUpdateClassroomEvaluationComponent,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomEvaluationComponent>({
      path: `${path ?? ""}/classroom-evaluation-components/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteClassroomEvaluationComponent = (
    id: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomEvaluationComponent>({
      path: `${path ?? ""}/classroom-evaluation-components/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ClassroomEvaluationComponentsService;
