import { IPublicNotificationQuery } from "../models/publicNotification/query";
import {
  ICreatePublicNotification,
  IUpdatePublicNotification,
} from "../models/publicNotification/request";
import {
  IPublicNotification,
  IPublicNotificationDetails,
} from "../models/publicNotification/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class NotificationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getMyNotifications = (
    query: IPublicNotificationQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<any>>({
      path: `${path ?? ""}/notifications/my-notifications`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getCountNotifications = (path?: string, params?: RequestParams) =>
    this.http.request<any>({
      path: `${path ?? ""}/notifications/unread-notifications-count`,
      method: "GET",
      secure: true,
      ...params,
    });
  readAllNotification = (path?: string, params?: RequestParams) =>
    this.http.request<any>({
      path: `${path ?? ""}/notifications/read-all`,
      method: "PUT",
      secure: true,
      ...params,
    });

  readNotification = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<any>({
      path: `${path ?? ""}/notifications/${id}/read`,
      method: "PUT",
      secure: true,
      ...params,
    });

  getTokenFcm = (params?: RequestParams) =>
    this.http.request<any>({
      path: `token-fcm`,
      method: "GET",
      secure: true,
      ...params,
    });
}

export default NotificationService;
