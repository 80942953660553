import { IEvaluationComponentQuery } from "../models/evaluation-components/query";
import { ICreateEvaluationComponent, IUpdateEvaluationComponent } from "../models/evaluation-components/request";
import { IEvaluationComponent, IEvaluationComponentDetails } from "../models/evaluation-components/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class EvaluationComponentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllEvaluationComponents = (query: IEvaluationComponentQuery, path?: string, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IEvaluationComponent>>({
      path: `${path ?? ""}/evaluation-components`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getEvaluationComponent = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IEvaluationComponentDetails>({
      path: `${path ?? ""}/evaluation-components/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createEvaluationComponent = (data: ICreateEvaluationComponent, path?: string, params?: RequestParams) =>
    this.http.request<IEvaluationComponent>({
      path: `${path ?? ""}/evaluation-components`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateEvaluationComponent = (
    id: number,
    data: IUpdateEvaluationComponent, path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IEvaluationComponent>({
      path: `${path ?? ""}/evaluation-components/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteEvaluationComponent = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IEvaluationComponent>({
      path: `${path ?? ""}/evaluation-components/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default EvaluationComponentService;
