import { HttpClient, RequestParams } from "./http-client";

export class TimeTableService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllTimeTables = (query: any, path?: string, params?: RequestParams) =>
    this.http.request<any[]>({
      path: `${path ?? ""}/session-timetable`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSuperTimeTables = (query: any, params?: RequestParams) =>
    this.http.request<any[]>({
      path: `/supervisor-panel/session-timetable`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });
}

export default TimeTableService;
