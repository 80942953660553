import { ISemesterDetails } from "../models/semesters/response";
import { ISpecializationQuery } from "../models/specialization/query";
import {
  ICreateSpecialization,
  IUpdateSpecialization,
} from "../models/specialization/request";
import {
  ISpecialization,
  ISpecializationDetails,
} from "../models/specialization/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class SpecializationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSpecializations = (
    query: ISpecializationQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ISpecialization>>({
      path: `${path ?? ""}/specializations`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSpecialization = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<ISpecializationDetails>({
      path: `${path ?? ""}/specializations/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createSpecialization = (
    data: ICreateSpecialization,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<ISpecialization>({
      path: `${path ?? ""}/specializations`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });

  updateSpecialization = (
    id: number,
    data: IUpdateSpecialization,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<ISpecialization>({
      path: `${path ?? ""}/specializations/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteSpecialization = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<ISpecialization>({
      path: `${path ?? ""}/specializations/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default SpecializationService;
