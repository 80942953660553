import { IWorkflowQuery } from "../models/workflow/query";
import { ICreateWorkflow, IUpdateWorkflow } from "../models/workflow/request";
import { IWorkflow, IWorkflowDetails } from "../models/workflow/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class WorkflowService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllWorkflows = (
    query: IWorkflowQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IWorkflow>>({
      path: `${path ?? ""}/workflows`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getWorkflow = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IWorkflowDetails>({
      path: `${path ?? ""}/workflows/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createWorkflow = (
    data: ICreateWorkflow,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IWorkflow>({
      path: `${path ?? ""}/workflows`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateWorkflow = (
    id: number,
    data: IUpdateWorkflow,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IWorkflow>({
      path: `${path ?? ""}/workflows/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  copyWorkflow = (id: number,
    data: any,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IWorkflow>({
      path: `${path ?? ""}/workflows/${id}/copy`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  copyMultiple = (id: number, semester: number,
    data: any,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IWorkflow>({
      path: `${path ?? ""}/workflows/${id}/classroomSemester/${semester}/copy-multiple`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  copyBook = (id: number,
    semester: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IWorkflow>({
      path: `${path ?? ""}/workflows/${id}/classroomSemester/${semester}/copy-book`,
      method: "POST",
      secure: true,
      ...params,
    });

  copySubject = (id: number,
    semester: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IWorkflow>({
      path: `${path ?? ""}/workflows/${id}/classroomSemester/${semester}/copy-subject`,
      method: "POST",
      secure: true,
      ...params,
    });

  copyClasses = (id: number,
    semester: number,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IWorkflow>({
      path: `${path ?? ""}/workflows/${id}/classroomSemester/${semester}/copy-classes`,
      method: "POST",
      secure: true,
      ...params,
    });


  deleteWorkflow = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IWorkflow>({
      path: `${path ?? ""}/workflows/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default WorkflowService;
