import { ILogin } from "../models/user-login/reques";
import { IUserLogin } from "../models/user-login/response";
import { IUpdateMe } from "../models/users/request";
import { HttpClient, RequestParams } from "./http-client";

export class Auth<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  login = (data: any, type: string, params?: RequestParams) =>
    this.http.request<IUserLogin>({
      path: `auth/${type}`,
      method: "POST",
      body: data,
      secure: false,
      ...params,
    });

  sendOtp = (data: any, params?: RequestParams) =>
    this.http.request<any>({
      path: `auth/send-mobile-otp`,
      method: "POST",
      body: data,
      secure: false,
      ...params,
    });

  changeSchool = (data: any, params?: RequestParams) =>
    this.http.request<any>({
      path: `/auth/change-school`,
      method: "put",
      body: data,
      secure: true,
      ...params,
    });

  me = (params?: RequestParams) =>
    this.http.request<IUserLogin>({
      path: `auth/me`,
      method: "GET",
      secure: true,
      ...params,
    });

  schoolUpdateMe = (data: IUpdateMe, params?: RequestParams) =>
    this.http.request<IUserLogin>({
      path: `auth/school-me`,
      method: "PUT",
      secure: true,
      body: data,
      ...params,
    });
}
