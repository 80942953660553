import { IStudentClassQuery } from "../models/studentClass/query";
import {
  ICreateStudentClass,
  IUpdateStudentClass,
} from "../models/studentClass/request";
import {
  IStudentClass,
  IStudentClassDetails,
} from "../models/studentClass/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentClassService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsClass = (query: IStudentClassQuery,
    path?: string, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStudentClass>>({
      path: `${ path == "supervisor-panel" ? "supervisor-panel/attendance" : `${path ?? ""}/student-class`}`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getTeachearStudentsClass = (
    query: IStudentClassQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudentClass>>({
      path: `/teacher-panel/student-class`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentClass = (id: number, params?: RequestParams) =>
    this.http.request<IStudentClassDetails>({
      path: `/student-class/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentClass = (data: ICreateStudentClass, params?: RequestParams) =>
    this.http.request<IStudentClass>({
      path: "/student-class",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentClass = (
    id: number,
    data: IUpdateStudentClass,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClass>({
      path: `/student-class/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  updateApprovalStudentClass = (
    id: number,
    data: any,
    params?: RequestParams
  ) =>
    this.http.request<any>({
      path: `/supervisor-panel/attendance/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentClass = (id: number, params?: RequestParams) =>
    this.http.request<IStudentClass>({
      path: `/student-class/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentClassService;
