import { IClassroomSemesterQuery } from "../models/classroomSemester/query";
import {
  ICreateClassroomSemester,
  IUpdateClassroomSemester,
} from "../models/classroomSemester/request";
import {
  IClassroomSemester,
  IClassroomSemesterDetails,
} from "../models/classroomSemester/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ClassroomSemestersService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClassroomSemes = (
    query: IClassroomSemesterQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClassroomSemester>>({
      path: `${path == "teacher-panel" ? `${path ?? ""}/classroom-semesters` : `${path ?? ""}/classroom-semester`}`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getClassroomSeme = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IClassroomSemesterDetails>({
      path: `${path ?? ""}/classroom-semester/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createClassroomSeme = (
    data: ICreateClassroomSemester,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomSemester>({
      path: `${path ?? ""}/classroom-semester`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateClassroomSeme = (
    id: number,
    data: any,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomSemester>({
      path: `${path ?? ""}/classroom-semester/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteClassroomSeme = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IClassroomSemester>({
      path: `${path ?? ""}/classroom-semester/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ClassroomSemestersService;
