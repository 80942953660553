import { IStudentsHealthQuery } from "../models/studentsHealth/query";
import {
  ICreateIStudentsHealth,
  IUpdateIStudentsHealth,
} from "../models/studentsHealth/request";
import { IStudentsHealthDetails } from "../models/studentsHealth/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentsHealthService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsHealth = (
    query: IStudentsHealthQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudentsHealthDetails>>({
      path: `school-panel/student-health`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentsHealth = (id: number, params?: RequestParams) =>
    this.http.request<IStudentsHealthDetails>({
      path: `school-panel/student-health/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentsHealth = (
    data: ICreateIStudentsHealth,
    params?: RequestParams
  ) =>
    this.http.request<IStudentsHealthDetails>({
      path: `school-panel/student-health`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentsHealth = (
    id: number,
    data: IUpdateIStudentsHealth,
    params?: RequestParams
  ) =>
    this.http.request<IStudentsHealthDetails>({
      path: `school-panel/student-health/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentsHealth = (id: number, params?: RequestParams) =>
    this.http.request<IStudentsHealthDetails>({
      path: `school-panel/student-health/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentsHealthService;
