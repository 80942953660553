import { IUpdateRole } from "../models/role/request";
import { IRole } from "../models/role/response";
import { ISkillQuery } from "../models/skills/query";
import { ISkill, ISkillDetails } from "../models/skills/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class RolesService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllRoles = (query: ISkillQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IRole>>({
      path: `/roles`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getRole = (id: number, params?: RequestParams) =>
    this.http.request<IRole>({
      path: `/roles/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createRole = (data: IUpdateRole, params?: RequestParams) =>
    this.http.request<IRole>({
      path: "/roles",
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });

  updateRole = (
    id: number,
    data: IUpdateRole,
    params?: RequestParams
  ) =>
    this.http.request<IRole>({
      path: `/roles/${id}`,
      method: "PUT",
      secure: true,
      body: data,
      ...params,
    });

  deleteRole = (id: number, params?: RequestParams) =>
    this.http.request<IRole>({
      path: `/roles/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default RolesService;
