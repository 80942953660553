import AcademicLevelService from "../api/AcademicLevels";
import ActualClassesService from "../api/ActualClasses";
import { Auth } from "../api/Auth";
import BooksService from "../api/Books";
import BuildingsService from "../api/buildings";
import CalanderService from "../api/Calander";
import ClassesService from "../api/Classes";
import ClassroomService from "../api/Classroom";
import ClassroomBooksService from "../api/ClassroomBooks";
import ClassroomEvaluationComponentsService from "../api/ClassroomEvaluationComponents";
import ClassroomSemestersService from "../api/ClassroomSemester";
import ClassroomSubjectsService from "../api/ClassroomSubjects";
import MarkService from "../api/degree";
import EmployeeService from "../api/employee";
import EvaluationComponentService from "../api/Evaluation-components";
import EvaluationLevelService from "../api/EvaluationLevel";
import EvaluationSystemsService from "../api/EvaluationSystems";
import GradesService from "../api/grades";
import GradeSubjectsService from "../api/GradeSubjects";
import GuardiansService from "../api/Guardians";
import HallsService from "../api/Hall";
import HallTypesService from "../api/HallTypes";
import HomeworksService from "../api/Homeworks";
import NotificationService from "../api/notification";
import PeriodAcademicService from "../api/periodAcademic";
import PermissionsService from "../api/permission";
import ProceduresService from "../api/Procedures";
import PublicNotificationService from "../api/PublicNotification";
import QualificationService from "../api/Qualifications";
import QualificationUserService from "../api/QualificationsUser";
import QuestionsService from "../api/Question";
import ReasonCancelService from "../api/ReasonCancel";
import ReportsService from "../api/Report";
import RolesService from "../api/role";
import SchoolsService from "../api/Schooles";
import SchoolProgramService from "../api/SchoolProgram";
import SchoolsUserService from "../api/SchoolsUser";
import SemesterService from "../api/Semesters";
import SettingService from "../api/Setting";
import SkillsService from "../api/Skills";
import SpecializationService from "../api/Specializations";
import Statistics from "../api/Statistics";
import StudentClassService from "../api/StudentClass";
import StudentClassBehaviorService from "../api/StudentClassBehavior";
import StudentClassSkillService from "../api/StudentClassSkill";
import StudentCustodiansService from "../api/StudentCustodians";
import StudentHobbyService from "../api/StudentHobby";
import StudentsService from "../api/Students";
import StudentsClassroomService from "../api/StudentsClassroom";
import StudentsHealthService from "../api/StudentsHealth";
import StudentsMessageService from "../api/StudentsMessage";
import SubjectsService from "../api/Subjects";
import SupervisorsService from "../api/Supervisors";
import TimeTableService from "../api/TimeTable";
import UsersService from "../api/Users";
import WorkflowService from "../api/Workflow";
import { httpclient } from "./http-client";

export default class EndPoints {
  //mangment
  public static auth = new Auth(httpclient);
  public static users = new UsersService(httpclient);
  public static questions = new QuestionsService(httpclient);
  public static employees = new EmployeeService(httpclient);
  public static guardians = new GuardiansService(httpclient);
  public static qualifications = new QualificationService(httpclient);
  public static qualificationsUser = new QualificationUserService(httpclient);
  public static schoolsUser = new SchoolsUserService(httpclient);
  public static periodAcademics = new PeriodAcademicService(httpclient);
  public static semesters = new SemesterService(httpclient);
  public static specializations = new SpecializationService(httpclient);
  public static evaluationComponents = new EvaluationComponentService(
    httpclient
  );
  public static evaluationSystems = new EvaluationSystemsService(httpclient);
  public static evaluationLevel = new EvaluationLevelService(httpclient);
  public static schooles = new SchoolsService(httpclient);
  public static academicLevels = new AcademicLevelService(httpclient);
  public static grades = new GradesService(httpclient);
  public static gradeSubjects = new GradeSubjectsService(httpclient);
  public static subjects = new SubjectsService(httpclient);
  public static skills = new SkillsService(httpclient);
  public static calander = new CalanderService(httpclient);
  public static reasonCancel = new ReasonCancelService(httpclient);
  public static procedures = new ProceduresService(httpclient);
  public static schoolProgram = new SchoolProgramService(httpclient);
  public static timeTable = new TimeTableService(httpclient);
  //schole building
  public static hallTypes = new HallTypesService(httpclient);
  public static halls = new HallsService(httpclient);
  public static buildings = new BuildingsService(httpclient);
  public static books = new BooksService(httpclient);
  // classroom
  public static classroom = new ClassroomService(httpclient);
  public static classroomSemesters = new ClassroomSemestersService(httpclient);
  public static supervisor = new SupervisorsService(httpclient);
  public static classes = new ClassesService(httpclient);
  public static classroomSubjects = new ClassroomSubjectsService(httpclient);
  public static classroomBooks = new ClassroomBooksService(httpclient);
  public static classroomEvaluationComponents =
    new ClassroomEvaluationComponentsService(httpclient);
  public static actualClasses = new ActualClassesService(httpclient);
  //workflow
  public static workflow = new WorkflowService(httpclient);
  //user
  public static students = new StudentsService(httpclient);
  public static studentCustodians = new StudentCustodiansService(httpclient);
  public static studentsClassroom = new StudentsClassroomService(httpclient);
  public static studentHealth = new StudentsHealthService(httpclient);
  public static studentHobby = new StudentHobbyService(httpclient);
  //report
  public static studentClass = new StudentClassService(httpclient);
  public static studentClassSkill = new StudentClassSkillService(httpclient);
  public static studentClassBehavior = new StudentClassBehaviorService(
    httpclient
  );
  public static degree = new MarkService(httpclient);
  public static role = new RolesService(httpclient);
  public static permission = new PermissionsService(httpclient);
  public static message = new StudentsMessageService(httpclient);
  public static setting = new SettingService(httpclient);
  public static homeworks = new HomeworksService(httpclient);
  public static statistics = new Statistics(httpclient);
  public static publicNotification = new PublicNotificationService(httpclient);
  public static notifications = new NotificationService(httpclient);
  public static reports = new ReportsService(httpclient)
}
