import {
  IAttendanceStatisticsQuery,
  ISessionsStatisticsQuery,
} from "../models/statistics/query";
import {
  IAttendanceStatistics,
  IPendingLogsStatistics,
  ISessionsStatistics,
  IUsersStatistics,
} from "../models/statistics/response";
import { HttpClient, RequestParams } from "./http-client";

export class Statistics<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAttendanceStatistics = (
    query?: IAttendanceStatisticsQuery,
    params?: RequestParams
  ) =>
    this.http.request<IAttendanceStatistics[]>({
      path: "/school-panel/attendances-statistics",
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSessionsStatistics = (
    query: any,
    params?: RequestParams
  ) =>
    this.http.request<ISessionsStatistics>({
      path: "/school-panel/sessions-statistics",
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getUsersStatistics = (params?: RequestParams) =>
    this.http.request<IUsersStatistics>({
      path: "/school-panel/users-statistics",
      method: "GET",
      secure: true,
      ...params,
    });

  getPendingLogsStatistics = (params?: RequestParams) =>
    this.http.request<IPendingLogsStatistics>({
      path: "/supervisor-panel/pending-logs-statistics",
      method: "GET",
      secure: true,
      ...params,
    });
}

export default Statistics;
