import { IStudentHobbyQuery } from "../models/studentHobby/query";
import {
  ICreateIStudentHobby,
  IUpdateIStudentHobby,
} from "../models/studentHobby/request";
import { IStudentHobbyDetails } from "../models/studentHobby/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentHobbyService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentHobby = (query: IStudentHobbyQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStudentHobbyDetails>>({
      path: `school-panel/student-hobby`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentHobby = (id: number, params?: RequestParams) =>
    this.http.request<IStudentHobbyDetails>({
      path: `school-panel/student-hobby/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentHobbyt = (data: ICreateIStudentHobby, params?: RequestParams) =>
    this.http.request<IStudentHobbyDetails>({
      path: `school-panel/student-hobby`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentHobby = (
    id: number,
    data: IUpdateIStudentHobby,
    params?: RequestParams
  ) =>
    this.http.request<IStudentHobbyDetails>({
      path: `school-panel/student-hobby/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentHobby = (id: number, params?: RequestParams) =>
    this.http.request<IStudentHobbyDetails>({
      path: `school-panel/student-hobby/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentHobbyService;
