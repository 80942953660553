import { IStudentClassSkillQuery } from "../models/studentClassSkill/query";
import {
  ICreateStudentClassSkill,
  IUpdateStudentClassSkill,
} from "../models/studentClassSkill/request";
import {
  IStudentClassSkill,
  IStudentClassSkillDetails,
} from "../models/studentClassSkill/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentClassSkillService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsClassSkill = (
    query: IStudentClassSkillQuery,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudentClassSkill>>({
      path: `${path ?? ""}/student-class-skill`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getTeachearStudentsClassSkill = (
    query: IStudentClassSkillQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudentClassSkill>>({
      path: `/teacher-panel/student-class-skill`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentClass = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<any>({
      path: `${path ?? ""}/student-class-skill/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentClass = (
    data: ICreateStudentClassSkill,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClassSkill>({
      path: `${path ?? ""}/student-class-skill`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentClass = (
    id: number,
    data: IUpdateStudentClassSkill,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClassSkill>({
      path: `${path ?? ""}/student-class-skill/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  updateApprovalSkillrClass = (id: number, data: any, params?: RequestParams) =>
    this.http.request<any>({
      path: `/supervisor-panel/student-class-skills/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentClass = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IStudentClassSkill>({
      path: `${path ?? ""}/student-class-skill/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentClassSkillService;
