import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";

import {
  isLoggedIn,
  getToken,
  setToken,
  setType,
  setExpiresIn,
  getExpiresIn,
} from "../services/authManager";
import { RootState } from "./store";
import EndPoints from "../services/end-points";
import { httpclient } from "../services/http-client";
import { errorNotification } from "../utils/helpers/notification";
import { IUserLogin } from "../models/user-login/response";
import { json } from "stream/consumers";
import { t } from "i18next";

enum LoadingEnum {
  IDLE = "idle",
  PENDING = "pending",
  SUCCESS = "succeeded",
  FAILED = "failed",
}

const initialState = {
  token: getToken(),
  isAuthenticated: isLoggedIn(),
  expires_in: Number(getExpiresIn()),
  loading: "idle",
  error: null,
} as {
  token: string | null;
  user: IUserLogin | null;
  isAuthenticated: boolean;
  expires_in: null | number;
  loading: LoadingEnum;
  error: null | SerializedError;
};
interface IRequestParams {
  username?: string;
  password?: string;
  type: string;
  s: () => void;
}

export const LoginApi = createAsyncThunk(
  "auth/login",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await EndPoints.auth.login(
        data.type == "employee-login"
          ? {
              identifier: data?.username || "",
              password: data?.password || "",
            }
          : {
              identifier: data?.identifier || "",
              code: data?.code || "",
            },
        data.type
      );

      if (data.s) data.s();
      if (
        response.data?.user?.type?.[0] !== "employee" &&
        response.data?.defaultSchool === null
      ) {
        await setToken(null);
        await setType(null, null);
        errorNotification(t("no_school"));
        return { ...response.data, token: null, user: null };
      } else {
        await setToken(response.data?.token ?? null);
        await setType(
          response.data?.user?.type?.[0] == "employee"
            ? response.data?.user?.type?.[0]
            : response.data?.defaultSchool?.type?.[0] ?? null,
          response.data?.defaultSchool?.type?.[0] == "employee"
            ? null
            : response.data?.defaultSchool?.school
            ? JSON.stringify(response.data?.defaultSchool?.school)
            : null
        );
        await httpclient.setSecurityData({
          token: `Bearer ${response.data?.token}`,
        });
        return response.data;
      }
    } catch (error: any) {
      errorNotification(error.response.data?.errors[0]);
      return rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (_, actionAfterLogout: any) => {
      setToken(null);
      setType(null, null);
      setExpiresIn(null);
      return {
        token: null,
        isAuthenticated: false,
        expires_in: null,
        loading: LoadingEnum.IDLE,
        error: null,
        user: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginApi.pending, (state) => {
        state.isAuthenticated = false;
        state.token = null;
        state.expires_in = null;
        state.loading = LoadingEnum.PENDING;
        state.error = null;
      })
      .addCase(LoginApi.fulfilled, (state, action) => {
        setToken(action.payload?.token ?? null);
        state.token = action.payload?.token ?? null;
        state.user = action.payload?.user ?? null;
        state.isAuthenticated = action.payload?.token == null ? false : true;
        state.loading = LoadingEnum.SUCCESS;
        state.error = null;
      })
      .addCase(LoginApi.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.token = null;
        state.expires_in = null;
        state.user = null;

        state.loading = LoadingEnum.FAILED;
        state.error = action.error;
      });
  },
});

export const { logout } = slice.actions;
export default slice.reducer;
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
