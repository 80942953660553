import { IHallQuery } from "../models/hall/query";
import { ICreateHall, IUpdateHall } from "../models/hall/request";
import { IHall, IHallDetails } from "../models/hall/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class HallsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllHalls = (query: IHallQuery, path?: string, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IHall>>({
      path: `${path ?? ""}/halls`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getHall = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IHallDetails>({
      path: `${path ?? ""}/halls/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createHall = (data: ICreateHall, path?: string, params?: RequestParams) =>
    this.http.request<IHall>({
      path: `${path ?? ""}/halls`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateHall = (
    id: number,
    data: IUpdateHall,
    path?: string,
    params?: RequestParams
  ) =>
    this.http.request<IHall>({
      path: `${path ?? ""}/halls/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteHall = (id: number, path?: string, params?: RequestParams) =>
    this.http.request<IHall>({
      path: `${path ?? ""}/halls/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default HallsService;
