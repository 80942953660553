import { IBaseQuery } from "../models/base/base-query";
import { ICreateHomework, IUpdateHomework } from "../models/homeworks/request";
import { IHomework, IHomeworkDetails } from "../models/homeworks/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class HomeworksService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllHomeworks = (query: IBaseQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IHomework>>({
      path: `/school-panel/homework`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getAllHomeworksTeachear = (query: IBaseQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IHomework>>({
      path: `/teacher-panel/homework`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });
  getAllHomeworksSuperVisor = (query: IBaseQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IHomework>>({
      path: `/supervisor-panel/homework`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getHomework = (id: number, params?: RequestParams) =>
    this.http.request<IHomeworkDetails>({
      path: `/school-panel/homework/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createHomework = (data: ICreateHomework, params?: RequestParams) =>
    this.http.request<IHomework>({
      path: "/school-panel/homework",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateHomework = (
    id: number,
    data: IUpdateHomework,
    params?: RequestParams
  ) =>
    this.http.request<IHomework>({
      path: `/school-panel/homework/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  updateApprovalHomework = (id: number, data: any, params?: RequestParams) =>
    this.http.request<any>({
      path: `/supervisor-panel/homework/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      ...params,
    });

  deleteHomework = (id: number, params?: RequestParams) =>
    this.http.request<IHomework>({
      path: `/school-panel/homework/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default HomeworksService;
